
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import salesCustomerGroupsDetailedGenerator, {
  TreemapMode,
} from '@/models/Charts/salesCustomerGroupsDetailedGenerator';
import { DetailedViewEnum } from '@/models/enums/WidgetEnum';
import { SalesCustomerGroupsDetailedData } from '@/models/Charts/chartsData';
import { EChartsOption } from 'echarts';
import { isEmpty } from '@/utils/misc';
import { ChartGenerator } from '@/models/Charts/abstract/chartGenerator';
import { tenantsService } from '@/services/tenants.service';

@Component({})
export default class DetailedCustomerGroups extends Vue {
  @Prop()
  private selectedTimeFilter!: [string, string];

  @Prop()
  private onClose?: () => void;

  private treemapMode: TreemapMode = 'all';
  private fields: TreemapMode[] = ['all', 'new', 'pending', 'expired'];
  private data: SalesCustomerGroupsDetailedData[] | null = [];
  private option: EChartsOption = {};
  private generator: ChartGenerator<SalesCustomerGroupsDetailedData[]> =
    new salesCustomerGroupsDetailedGenerator(
      DetailedViewEnum.SalesCustomerGroupsDetailed,
      this.tenantIdDh,
    );

  private modalOpen = true;
  private noData = false;
  private loading = false;

  private async mounted() {
    await this.update();
  }

  private async update(): Promise<void> {
    this.noData = false;
    this.loading = true;
    if (isEmpty(this.data)) {
      this.data = await this.generator.getData([], [], this.selectedTimeFilter);
    }

    if (isEmpty(this.data)) {
      this.noData = true;
      this.loading = false;
      return;
    }

    const params = {
      mode: this.treemapMode,
    };
    this.option = this.generator.updateOptions(this.data!, params);
    this.loading = false;
  }

  private async onModeChange(value: TreemapMode) {
    this.treemapMode = value;
    await this.update();
  }

  @Watch('modalOpen')
  private onModalOpenChanged() {
    if (!this.modalOpen && !!this.onClose) {
      this.onClose();
    }
  }

  private beforeDestroy() {
    this.generator?.abort();
  }

  private get tenantIdDh(): number {
    return tenantsService.store.currentIdDh()!;
  }
}
