// --------------------------------------------------------------------------------
// <copyright file="salesCustomerGroupsDetailedGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { getColor } from '@/utils/color';
import { numberWithCurrency } from '@/utils/number';
import moment from 'moment';
import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import { SalesCustomerGroupsDetailedData } from './chartsData';
import { EChartsOption } from 'echarts';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { GeneratorParams } from './generatorParams';
import { isEmpty } from '@/utils/misc';
import { metricsService } from '@/services/metrics.service';

export type TreemapMode = 'all' | 'new' | 'pending' | 'expired';

export default class SalesCustomerGroupsDetailedGenerator extends ChartGenerator<
  SalesCustomerGroupsDetailedData[]
> {
  private readonly levels = [
    {
      itemStyle: {
        gapWidth: 5,
        borderWidth: 3,
      },
    },
    {
      itemStyle: {
        gapWidth: 1,
      },
    },
  ];

  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];
    return metricsService.getSMBSMetricsBC<SalesCustomerGroupsDetailedData[]>(
      this.procedure,
      this.tenantIdDh,
      {
        date: moment().format('YYYY-MM-DD'),
        startDate,
        endDate,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: SalesCustomerGroupsDetailedData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const categories = ['A', 'B', 'C'];
    const mode = parameters.mode ?? 'all';

    if (isEmpty(data)) {
      return {};
    }

    const seriesFilterFunction = this.getFilterFunction(mode);

    return {
      title: {
        show: false,
      },
      legend: {
        type: 'scroll',
        itemStyle: {
          color: '#5e5e5e',
        },
        data: [
          i18n.t('report.business_console.sold_amt'),
          i18n.t('report.business_console.invoiced_amt'),
          i18n.t('report.business_console.paid_amt'),
        ],
        bottom: 0,
        selectedMode: 'single',
        borderRadius: 5,
        backgroundColor: 'white',
      },
      label: {
        position: 'insideTopLeft',
        overflow: 'breakAll',
        formatter: (params: any) => {
          const { seriesName } = params;
          const customer = data.find((entry) => entry.customer === params.name);
          if (!customer) {
            return;
          }
          const [category] = params.data.path.split('/');
          const commonElements = [
            `{title|${params.name}} {category|${i18n.t('report.category', {
              category,
            })}}`,
            '{hr|}',
          ];
          switch (seriesName) {
            case i18n.t('report.business_console.sold_amt'):
              return [...commonElements, ...this.salesLabels(customer)].join('\n');
            case i18n.t('report.business_console.invoiced_amt'):
              return [...commonElements, ...this.invoicesLabels(customer)].join('\n');
            case i18n.t('report.business_console.paid_amt'):
              return [...commonElements, ...this.paidLabels(customer)].join('\n');
          }
        },
        rich: {
          label: {
            fontSize: 9,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            borderRadius: 2,
            padding: [2, 4],
            align: 'right',
          },
          title: {
            fontSize: 14,
            fontWeight: 'bold',
            padding: 3,
          },
          category: {
            align: 'right',
          },
          hr: {
            width: '100%',
            borderColor: 'rgba(255, 255, 255, 0.2)',
            borderWidth: 0.5,
            height: 0,
            lineHeight: 10,
          },
          highlight: {
            padding: 4,
            fontSize: 12,
            fontWeight: 'bold',
          },
          line: {
            padding: 3,
          },
        },
      },
      series: [
        {
          name: i18n.t('report.business_console.sold_amt').toString(),
          type: 'treemap',
          levels: this.levels,
          breadcrumb: {
            top: 0,
            bottom: 'auto',
          },
          leafDepth: 3,
          data: categories.map((category, i) => ({
            name: category,
            path: category,
            children: data
              .filter((entry) => entry.category_sales === category)
              .filter(seriesFilterFunction)
              .map((entry) => ({
                value: entry.sales,
                name: entry.customer,
                path: `${category}/${entry.customer}`,
              })),
            itemStyle: {
              color: getColor(i, 0),
            },
          })),
        },
        {
          name: i18n.t('report.business_console.invoiced_amt').toString(),
          type: 'treemap',
          levels: this.levels,
          breadcrumb: {
            top: 0,
            bottom: 'auto',
          },
          leafDepth: 3,
          data: categories.map((category, i) => ({
            name: category,
            path: category,
            children: data
              .filter((entry) => entry.category_invoice === category)
              .filter(seriesFilterFunction)
              .map((entry) => ({
                value: entry.sales_invoiced,
                name: entry.customer,
                path: `${category}/${entry.customer}`,
              })),
            itemStyle: {
              color: getColor(i, 0),
            },
          })),
        },
        {
          name: i18n.t('report.business_console.paid_amt').toString(),
          type: 'treemap',
          levels: this.levels,
          breadcrumb: {
            top: 0,
            bottom: 'auto',
          },
          leafDepth: 3,
          data: categories.map((category, i) => ({
            name: category,
            path: category,
            children: data
              .filter((entry) => entry.category_paid === category)
              .filter(seriesFilterFunction)
              .map((entry) => ({
                value: entry.invoices_paid,
                name: entry.customer,
                path: `${category}/${entry.customer}`,
              })),
            itemStyle: {
              color: getColor(i, 0),
            },
          })),
        },
      ],
    };
  }

  private salesLabels(customer: SalesCustomerGroupsDetailedData): string[] {
    return [
      `{highlight|${numberWithCurrency(customer.sales)}} {label|${i18n.t(
        'report.business_console.sold_amt',
      )}}`,
      `{line|${numberWithCurrency(customer.quotes_converted)}} {label|${i18n.t(
        'report.business_console.converted',
      )}}`,
      `{line|${numberWithCurrency(customer.quotes_converted - customer.sales)}} {label|${i18n.t(
        'report.business_console.converted_sub_sold',
      )}}`,
      `{line|${i18n.n(customer.quotes_converted / customer.sales)}} {label|${i18n.t(
        'report.business_console.converted_to_sold_ratio',
      )}}`,
    ];
  }

  private invoicesLabels(customer: SalesCustomerGroupsDetailedData): string[] {
    const el = [
      `{highlight|${numberWithCurrency(customer.sales_invoiced)}} {label|${i18n.t(
        'report.business_console.invoiced_amt',
      )}}`,
      `{line|${numberWithCurrency(customer.sales)}} {label|${i18n.t(
        'report.business_console.sold_amt',
      )}}`,
      `{line|${numberWithCurrency(customer.sales - customer.sales_invoiced)}} {label|${i18n.t(
        'report.business_console.sold_sub_invoiced',
      )}}`,
      `{line|${i18n.n(customer.sales_invoiced / customer.sales)}} {label|${i18n.t(
        'report.business_console.invoiced_to_sold_ratio',
      )}}`,
    ];
    return el;
  }

  private paidLabels(customer: SalesCustomerGroupsDetailedData): string[] {
    const el = [
      `{highlight|${numberWithCurrency(customer.invoices_paid)}} {label|${i18n.t(
        'report.business_console.paid_amt',
      )}}`,
      `{line|${numberWithCurrency(customer.sales_invoiced)}} {label|${i18n.t(
        'report.business_console.invoiced_amt',
      )}}`,
      `{line|${numberWithCurrency(
        customer.sales_invoiced - customer.invoices_paid,
      )}} {label|${i18n.t('report.business_console.invoiced_sub_paid')}}`,
      `{line|${i18n.n(customer.invoices_paid / customer.sales_invoiced)}} {label|${i18n.t(
        'report.business_console.paid_to_invoiced_ratio',
      )}}`,
    ];
    return el;
  }

  private getFilterFunction(mode: TreemapMode): (e: SalesCustomerGroupsDetailedData) => boolean {
    switch (mode) {
      case 'new':
        return (e) => e.new_customer;
      case 'pending':
        return (e) => e.payments_pending;
      case 'expired':
        return (e) => e.invoices_expired;
      case 'all':
      default:
        return () => true;
    }
  }
}
